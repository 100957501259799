<script>
export default {
  name: "WidgetView",
  data() {
    return {}
  },
  mounted() {
    new window.CDEKWidget({
      root: 'cdek-map',
      apiKey: 'aa4f6a76-8e7e-4a52-bf91-841299f8b6a1',
      servicePath: 'index.php',
      defaultLocation: 'Москва',
      debug: true,
      hideFilters: {
        have_cashless: false,
        is_dressing_room: false
      },
      forceFilters: {
        type: 'PVZ'
      },
      hideDeliveryOptions: {
        door: true
      },
      onChoose(test, test2, val) {
        console.log(val)
        localStorage.setItem('pvz', JSON.stringify(val))
      }
    });
  }
}
</script>

<template>
  <div>
    <div id="cdek-map" class="cdek_style"></div>
  </div>
</template>

<style scoped>
.cdek_style {
  width: 800px;
  height: 600px
}
@media screen and (max-width: 700px) {
  .cdek_style{
    height: 300px
  }
}
</style>
