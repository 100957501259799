import 'bootstrap/dist/css/bootstrap.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Vue from 'vue' // in Vue 3
import axios from 'axios'
import VueAxios from 'vue-axios'
import vSelect from 'vue-select'
import 'bootstrap/dist/js/bootstrap'
import 'vue-select/dist/vue-select.css';
import './css/style.css'
import "vue-select/dist/vue-select.css";


createApp(App).component("v-select", vSelect)
createApp(App).use(router).mount('#app')


const app = Vue.createApp(axios)
app.use(VueAxios, axios)

