<template>
  <div class="delivery__main">
    <div class="fixed-overlay fixed-overlay__delivery">
      <div class="delivery">
        <div class="delivery_container" :class="type === 'pochta_delivery'? 'width-760': false">
          <div class="delivery_content"
               :class="type === 'main_screen'? 'p-l-320': type === 'finish_screen'? 'p-l-200' : false">
            <main-screen-view v-if="type === 'main_screen' && fields !== null"
                              :fields="fields"
                              @type="type = $event"></main-screen-view>
            <sdek-delivery-view v-if="type === 'sdek_delivery' && fields !== null" :fields="fields" :token="token"
                                @finish="loadFields" @type="type = $event"
                                @finish_banner="finish_banner = $event"></sdek-delivery-view>
            <pochta-delivery-view v-if="type === 'pochta_delivery' && fields !== null" :fields="fields" :token="token"
                                  @finish="loadFields" @type="type = $event"
                                  @finish_banner="finish_banner = $event"></pochta-delivery-view>
            <finish-screen-view v-if="type === 'finish_screen' && fields !== null && finish_banner === true"
                                :delivery_type="delivery_type" :fields="fields"
                                @type="type = $event"></finish-screen-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {toast} from 'vue3-toastify';
import MainScreenView from "@/components/MainScreenView.vue";
import SdekDeliveryView from "@/components/SdekDeliveryView.vue";
import PochtaDeliveryView from "@/components/PochtaDeliveryView.vue";
import FinishScreenView from "@/components/FinishScreenView.vue";

export default {
  name: 'DeliveryView',
  components: {MainScreenView, SdekDeliveryView, PochtaDeliveryView, FinishScreenView},
  data() {
    return {
      token: null,
      fields: null,
      show: 'cdek',

      type: 'main_screen',
      delivery_type: null,
      finish_banner: false
    }
  },
  watch: {
    finish_banner(){
      if (this.finish_banner === true) {
        if (this.delivery_type !== null){
          this.type = 'finish_screen'
        }
      }
    }
  },
  methods: {
    loadFields() {
      console.log(this.token, 'token')
      axios.post('https://platform-easycode.ru/api/delivery/fields', {
        token: this.token
      }).then((res) => {
        this.fields = res.data.data
        this.delivery_type = res.data.data.delivery_type
      }).catch(err => {
        console.log(err.response)
        toast("Что то пошло не так, обратитесь к менеджеру за помощью", {
          "theme": "colored",
          "type": "error",
          "position": "bottom-right",
          "dangerouslyHTMLString": true
        });
      })
    },
  },
  mounted() {
    this.token = this.$route.query.key
    this.loadFields()
  }
}
</script>

<style scoped>

</style>
