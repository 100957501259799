<script>
export default {
  name: "MainScreenView",
  props: ['fields', 'delivery_type']
}
</script>

<template>
  <div>
    <div class="delivery_content" v-if="delivery_type === 'cdek'">
      <div>
        <img class="delivery_content__img_finish" src="../assets/icon_finish.png" alt="">
      </div>
      <div class="delivery_content__text">
        <div class="delivery_content__text__title">Бежим упаковывать подарок!</div>
        <div class="delivery_content__text__subtext">Спасибо, что заполнили форму!</div>
        <div class="delivery_content__text__subtext_sub" v-if="fields.values.length > 0">Вы выбрали пункт выдачи СДЭК<br> по адресу {{ fields.values.find((i)=> i.field_id === 20).field_value }} , {{ fields.values.find((i)=> i.field_id === 17).field_value }}.<br> Заказ доставят в
          ближайшее время!
        </div>
      </div>
    </div>
    <div class="delivery_content" v-if="delivery_type === 'pochta'">
      <div>
        <img class="delivery_content__img_finish" src="../assets/icon_finish.png" alt="">
      </div>
      <div class="delivery_content__text">
        <div class="delivery_content__text__title">Бежим упаковывать подарок!</div>
        <div class="delivery_content__text__subtext">Спасибо, что заполнили форму!</div>
        <div class="delivery_content__text__subtext_sub" v-if="fields.fields.length > 0">Вы собрали доставку почтой РФ<br> по адресу {{ fields.fields.find((i)=> i.id === 5).value }} , {{ fields.fields.find((i)=> i.id === 8).value }} , дом {{ fields.fields.find((i)=> i.id === 9).value }}.кв {{ fields.fields.find((i)=> i.id === 10).value  }}. <br> Заказ доставят в
          ближайшее время!
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.delivery_content__img_finish{
  position: absolute;
  left: 0;
  bottom: 0;
}
</style>