import { createRouter, createWebHistory } from 'vue-router'
import DeliveryView from "@/views/DeliveryView.vue";

const routes = [
  {
    name: 'delivery',
    path: '/delivery',
    component: DeliveryView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
