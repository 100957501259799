<script>
import VueSelect from "vue-select"
import {useVuelidate} from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
import { vMaska } from "maska"


export default {
  setup: () => ({v$: useVuelidate()}),
  components: {
    VueSelect
  },
  directives: { maska: vMaska },
  data() {
    return {
      value: [],
      name: this.fields.fields[7].value,
      phone: this.fields.fields[11].value,
      street: this.fields.fields[2].value,
      house: this.fields.fields[3].value,
      apartment: this.fields.fields[4].value,
      index: this.fields.fields[5].value,
      region: this.fields.fields[1].value,
      city: this.fields.fields[0].value,
      regions: [],
      cities: [],
      policy_checkbox: true
    };
  },
  watch: {
    region() {
      this.loadPochtaCity()
    },
  },
  validations() {
    return {
      name: {required},
      phone: {required},
      street: {required},
      house: {required},
      apartment: {required},
      index: {required},
      region: {required},
      city: {required},
    }
  },
  props: ['token', 'fields'],
  methods: {
    sendForm() {
      this.v$.$validate()
      if (!this.v$.$error) {
        axios.post('https://platform-easycode.ru/api/delivery/saveFields', {
          token: this.token,
          values: [
            {
              field_id: 12,
              value: this.name
            },
            {
              field_id: 15,
              value: this.phone
            },
            {
              field_id: 4,
              value: this.city
            },
            {
              field_id: 5,
              value: this.region
            },
            {
              field_id: 8,
              value: this.street
            },
            {
              field_id: 9,
              value: this.house
            },
            {
              field_id: 10,
              value: this.apartment
            },
            {
              field_id: 11,
              value: this.index
            },
          ],
        }).then((res) => {
          console.log('Данные отправились', res)
          this.$emit('finish')
          this.$emit('finish_banner', true)
        }).catch(err => {
          console.log(err.response)
          toast("Что то пошло не так, обратитесь к менеджеру за помощью", {
            "theme": "colored",
            "type": "error",
            "position": "bottom-right",
            "dangerouslyHTMLString": true
          });
        })
      } else {
        toast("Данные не все заполнены", {
          "theme": "colored",
          "type": "error",
          "position": "bottom-right",
          "dangerouslyHTMLString": true
        }); // ToastOptions
      }
    },
    loadPochtaCity() {
      axios.get('/city.php').then((res) => {
        console.log(res.data)

        this.regions = []
        let regions = []
        Object.keys(res.data).forEach(function (key) {
          regions.push(key)
        });
        this.regions = regions
        this.cities = res.data[this.region]
      })
    },
  },
  mounted() {
    this.loadPochtaCity()
    // console.log(document.getElementsByClassName('vs__clear')[0])
    // document.getElementsByClassName('vs__clear')[0].stylе.display = "none";

    var elems = document.getElementsByClassName('vs__clear');
    for (var i = 0; i < elems.length; i++) elems[i].style.display = 'none';
  }
}
</script>

<template>
  <div>
    <div>
      <input class="sdek_delivery__input" :style="v$.name.$error ? 'border-color: #7d0d0d42':false" v-model="name"
             placeholder="ФИО">
    </div>
    <div v-if="v$.name.$error" class="validate_style_text">Заполните имя</div>
      <input class="sdek_delivery__input" :style="v$.name.$error ? 'border-color: #7d0d0d42':false" v-maska data-maska="+7 (###) ###-##-##" v-model="phone"
             placeholder="Номер телефона">
    <div v-if="v$.name.$error" class="validate_style_text">Заполните номер</div>
    <div class="row sdek_delivery__content">
      <div class="col-6 sdek_delivery__title">
        Доставка Почтой России
      </div>
      <div class="col-6 text-end sdek_delivery__not">
        <span @click="this.$emit('type','sdek_delivery')" class="sdek_delivery__text cursor-pointer">
          Хочу доставку СДЭК
        </span>
      </div>
    </div>
    <div>
      <!--      <div>-->
      <!--        <vue-select :options="options" class="vue_select__style" placeholder="Город / село" label="name"/>-->
      <!--      </div>-->
      <div>
        <vue-select :options="regions" v-model="region" class="vue_select__style" placeholder="Область / Край"
                    label="name"/>
      </div>
      <div v-if="v$.street.$error" class="validate_style_text">Заполните регион</div>
      <div class="mt-15">
        <vue-select :options="cities" v-model="city" class="vue_select__style" placeholder="Город / село" label="name"/>
      </div>
      <div v-if="v$.street.$error" class="validate_style_text">Заполните город</div>
      <div>
        <input class="sdek_delivery__input" :style="v$.street.$error ? 'border-color: #7d0d0d42':false" v-model="street"
               placeholder="Улица">
      </div>
      <div v-if="v$.street.$error" class="validate_style_text">Заполните улицу</div>
      <div>
        <input class="sdek_delivery__input" :style="v$.house.$error ? 'border-color: #7d0d0d42':false" v-model="house"
               placeholder="Дом">
      </div>
      <div v-if="v$.house.$error" class="validate_style_text">Заполните дом</div>
      <div>
        <input class="sdek_delivery__input" :style="v$.apartment.$error ? 'border-color: #7d0d0d42':false"
               v-model="apartment"
               placeholder="Квартира">
      </div>
      <div v-if="v$.apartment.$error" class="validate_style_text">Заполните квартиру</div>
      <div>
        <input class="sdek_delivery__input" :style="v$.index.$error ? 'border-color: #7d0d0d42':false" v-model="index" v-maska data-maska="######"
               placeholder="Индекс">
      </div>
      <div v-if="v$.index.$error" class="validate_style_text">Заполните индекс</div>
      <div class="policy__content">
        <span class="form-check">
          <input class="form-check-input" checked type="checkbox" v-model="policy_checkbox">
        </span>
        <span>
          <a class="policy__content__text">
          Я согласен с
          </a>
        </span>
        <span>
         <a href="https://easycode.tech/oferta2" target="_blank" class="policy__content__link">
           политикой обработки персональных данных
         </a>
        </span>
      </div>
    </div>
    <div class="row sdek__btn">
      <div class="col-6 text-center problem__btn__position">
        <a href="http://wa.me/79950950560?text=Здравствуйте,%20у%20меня%20вопрос%20по%20полям%20доставки" target="_blank"
           class="btn problem__btn">У меня проблема</a>
      </div>
      <div class="col-6 text-center problem__btn__position">
        <button @click="sendForm" :class="policy_checkbox === false ? 'disabled' : false" class="btn btn-primary send__btn">Отправить</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.sdek_delivery__input {
  height: 52px;
  padding: 30px 20px 12px 20px;
  align-items: flex-end;
  gap: 4px;
  align-self: stretch;
  border: none;
  outline: none;
  width: 100%;

  border-bottom: 1px solid #CAD9FF
}

.sdek_delivery__content {
  margin-top: 40px;
  margin-bottom: 30px;
}

.sdek_delivery__title {
  color: #24292D;

  font-family: 'Montserrat-Bold';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sdek_delivery__text {
  color: #134DE2;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #134DE2;
}

.mt-36 {
  margin-top: 36px;
}

.vue_select__style {
  color: rgba(36, 41, 45, 0.60);
  font-size: 16px;
}

.policy__content {
  margin-top: 30px;

  display: flex;
  align-items: center;
}

.policy__content__text {
  color: #24292D;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  text-decoration: none;
}

.policy__content__link {
  opacity: 0.8;
  color: #134DE2;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  border-bottom: 1px solid #134DE2;

  margin-left: 3px;
}

.sdek__btn {
  margin-top: 15px;
}

.problem__btn {
  display: flex;
  padding: 16px 60px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 16px;
  border: 1px solid #CAD9FF;

  color: #134DE2;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  width: 100%;
}

.validate_style_text {
  color: rgba(125, 13, 13, 0.62);
  padding-left: 10px;
}
</style>