<script>
export default {
  name: "MainScreenView",
  props: ['fields']
}
</script>

<template>
  <div>
    <img class="delivery_content__img" src="../assets/icon_cat.png" alt="">
  </div>
  <div class="delivery_content__text" v-if="fields !== null && fields.products.length > 0">
    <div class="delivery_content__text__title">Школа <span class="color__134DE2">EasyCode</span><br> на связи!</div>
      <div class="delivery_content__text__subtext" v-if="fields.products.length === 1">
        {{ fields.user.name }} усердно учился и накопил достаточно<br> изикоинов, чтобы обменять их на <span v-for="product in fields.products" :key="product.id">{{ product.name }}</span>
      </div>
    <div class="delivery_content__text__subtext" v-if="fields.products.length > 1">
      {{ fields.user.name }} усердно учился и накопил достаточно<br> изикоинов, чтобы обменять их на:
      <div>
        <ul>
          <li v-for="product in fields.products" :key="product.id"><span>{{ product.name }}</span></li>
        </ul>
      </div>
    </div>
      <div class="delivery_content__text__subtext_sub">
        Заполните, пожалуйста, свои данные, чтобы мы смогли<br> отправить вам подарок!
      </div>
      <div class="delivery_btn__content">
        <button @click="this.$emit('type','sdek_delivery')" class="btn-primary btn w-100 delivery_btn">
          Заполнить
        </button>
      </div>
  </div>
  <div class="delivery_content__text" v-else>
    <div class="delivery_content__text__title">Школа <span class="color__134DE2">EasyCode</span><br> на связи!</div>
    <div class="delivery_content__text__subtext">
      <span>Кажется, что то пошло не так :(</span>
    </div>
    <div class="delivery_content__text__subtext_sub">
      Обратитесь пожалуйста к менеджеру за помощью!
    </div>
    <div class="delivery_btn__content">
      <a href="http://wa.me/79950950560?text=Здравствуйте,%20у%20меня%20вопрос%20по%20полям%20доставки" class="btn-primary btn w-100 delivery_btn">
        Обратиться к менеджеру
      </a>
    </div>
  </div>
</template>

<style scoped>
.delivery_content__img{
  position: absolute;
  left: -10%;
  bottom: 0;
}
 li {
   color: #134de2;
 }
 li span {
   color: #24292D;
 }
</style>